// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function stringToRole(value) {
  switch (value) {
    case "manager" :
        return "Manager";
    case "member" :
        return "Member";
    case "owner" :
        return "Owner";
    default:
      return "Other";
  }
}

function roleToString(role) {
  switch (role) {
    case "Owner" :
        return "Owner";
    case "Manager" :
        return "Manager";
    case "Member" :
        return "Member";
    case "Other" :
        return "Other";
    
  }
}

function roleToDowncase(role) {
  switch (role) {
    case "Owner" :
        return "owner";
    case "Manager" :
        return "manager";
    case "Member" :
        return "member";
    case "Other" :
        return "other";
    
  }
}

function stringToStatus(value) {
  switch (value) {
    case "accepted" :
        return "Accepted";
    case "pending" :
        return "Pending";
    case "rejected" :
        return "Rejected";
    default:
      return "Other";
  }
}

function statusToString(status) {
  switch (status) {
    case "Pending" :
        return "Pending";
    case "Rejected" :
        return "Rejected";
    case "Accepted" :
        return "Accepted";
    case "Other" :
        return "Other";
    
  }
}

function fromJs(js) {
  return {
          id: js.id,
          providerRole: stringToRole(js.providerRole),
          status: stringToStatus(js.status),
          name: js.name,
          jobTitle: js.jobTitle,
          email: js.email,
          invitedAt: $$Date.Naive.fromString(js.invitedAt),
          profilePic: js.profilePic
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerRole: stringToRole(field.required("providerRole", Json_Decode$JsonCombinators.string)),
              status: stringToStatus(field.required("status", Json_Decode$JsonCombinators.string)),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              jobTitle: field.required("jobTitle", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              invitedAt: field.required("invitedAt", $$Date.Naive.decoder),
              profilePic: field.required("profilePic", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Edit = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          users: Belt_Array.map(js.users, fromJs)
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              users: field.required("users", Json_Decode$JsonCombinators.array(decoder))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var EditProvider = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$2(js) {
  return {
          id: js.id,
          providerRole: stringToRole(js.providerRole),
          status: stringToStatus(js.status),
          userId: js.userId,
          providerId: js.providerId,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt),
          invitedAt: $$Date.Naive.fromString(js.invitedAt)
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerRole: stringToRole(field.required("providerRole", Json_Decode$JsonCombinators.string)),
              status: stringToStatus(field.required("status", Json_Decode$JsonCombinators.string)),
              userId: field.required("userId", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              invitedAt: field.required("invitedAt", $$Date.Naive.decoder)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

export {
  stringToRole ,
  roleToString ,
  roleToDowncase ,
  stringToStatus ,
  statusToString ,
  Edit ,
  EditProvider ,
  fromJs$2 as fromJs,
  decoder$2 as decoder,
  fromJson$2 as fromJson,
}
/* decoder Not a pure module */
