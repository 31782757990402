// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toString(x) {
  switch (x) {
    case "Cloudinary" :
        return "cloudinary";
    case "YouTube" :
        return "youtube";
    case "Vimeo" :
        return "vimeo";
    
  }
}

function fromString(x) {
  switch (x) {
    case "cloudinary" :
        return "Cloudinary";
    case "vimeo" :
        return "Vimeo";
    case "youtube" :
        return "YouTube";
    default:
      return PervasivesU.failwith("Unknown video source");
  }
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromString);

var Source = {
  toString: toString,
  fromString: fromString,
  decoder: decoder
};

function fromJs(js) {
  return {
          id: js.id,
          publicId: js.publicId,
          source: fromString(js.source),
          url: js.url,
          width: Caml_option.nullable_to_opt(js.width),
          height: Caml_option.nullable_to_opt(js.height),
          active: js.active,
          description: js.description,
          position: js.position
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              publicId: field.required("publicId", Json_Decode$JsonCombinators.string),
              source: field.required("source", decoder),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              width: field.required("width", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              height: field.required("height", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              position: field.required("position", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function normalize(videos) {
  return {
          index: Belt_Array.map(videos, (function (video) {
                  return video.id;
                })),
          dict: Belt_Array.reduce(videos, ID.$$Map.make(), (function (dict, video) {
                  return Belt_Map.set(dict, video.id, video);
                }))
        };
}

function fromCloudinary(json, position) {
  return Json_Encode$JsonCombinators.object([
              [
                "publicId",
                Belt_Result.getExn(Json$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                              return field.required("public_id", Json_Decode$JsonCombinators.string);
                            })))
              ],
              [
                "source",
                "cloudinary"
              ],
              [
                "width",
                Belt_Result.getExn(Json$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                              return field.required("width", Json_Decode$JsonCombinators.$$int);
                            })))
              ],
              [
                "height",
                Belt_Result.getExn(Json$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                              return field.required("height", Json_Decode$JsonCombinators.$$int);
                            })))
              ],
              [
                "url",
                Belt_Result.getExn(Json$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                              return field.required("url", Json_Decode$JsonCombinators.string);
                            })))
              ],
              [
                "position",
                position
              ]
            ]);
}

function fromYouTube(url, publicId, height, width) {
  return Json_Encode$JsonCombinators.object([
              [
                "publicId",
                publicId
              ],
              [
                "source",
                "youtube"
              ],
              [
                "url",
                url
              ],
              [
                "height",
                height
              ],
              [
                "width",
                width
              ]
            ]);
}

function fromVimeo(url, publicId, height, width) {
  return Json_Encode$JsonCombinators.object([
              [
                "publicId",
                publicId
              ],
              [
                "source",
                "vimeo"
              ],
              [
                "url",
                url
              ],
              [
                "height",
                height
              ],
              [
                "width",
                width
              ]
            ]);
}

var Interim = {
  fromCloudinary: fromCloudinary,
  fromYouTube: fromYouTube,
  fromVimeo: fromVimeo
};

function dummyVideoFromYouTube(publicId) {
  return {
          id: 1,
          publicId: publicId,
          source: "YouTube",
          url: "https://www.youtube.com/watch?v=" + publicId,
          width: undefined,
          height: undefined,
          active: true,
          description: "",
          position: 1
        };
}

export {
  Source ,
  fromJs ,
  decoder$1 as decoder,
  fromJson ,
  normalize ,
  Interim ,
  dummyVideoFromYouTube ,
}
/* decoder Not a pure module */
