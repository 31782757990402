// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as ProjectSettingSubcategory from "./ProjectSettingSubcategory.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          title: js.title,
          subcategories: Belt_Array.map(js.subcategories, ProjectSettingSubcategory.EditProvider.fromJs)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              subcategories: field.required("subcategories", Json_Decode$JsonCombinators.array(ProjectSettingSubcategory.EditProvider.decoder))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var EditProvider = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          id: js.id,
          title: js.title,
          active: js.active,
          slug: js.slug,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("createdAt", $$Date.Naive.decoder)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

export {
  EditProvider ,
  fromJs$1 as fromJs,
  decoder$1 as decoder,
  fromJson$1 as fromJson,
}
/* decoder Not a pure module */
