// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          providerId: js.providerId,
          master: js.master,
          agreement: js.agreement,
          colocationMarketplace: js.colocationMarketplace,
          colocationDirectory: js.colocationDirectory,
          cloudDirectory: js.cloudDirectory,
          internetDirectory: js.internetDirectory,
          bareMetalDirectory: js.bareMetalDirectory,
          networkDirectory: js.networkDirectory,
          colocationProjects: js.colocationProjects,
          internetProjects: js.internetProjects,
          cloudIaasProjects: js.cloudIaasProjects,
          cloudServersProjects: js.cloudServersProjects,
          networkSdwanProjects: js.networkSdwanProjects,
          networkMplsProjects: js.networkMplsProjects,
          networkPrivateLineProjects: js.networkPrivateLineProjects,
          bareMetalProjects: js.bareMetalProjects,
          resourceDownloadLeads: js.resourceDownloadLeads
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              master: field.required("master", Json_Decode$JsonCombinators.string),
              agreement: field.required("agreement", Json_Decode$JsonCombinators.bool),
              colocationMarketplace: field.required("colocationMarketplace", Json_Decode$JsonCombinators.bool),
              colocationDirectory: field.required("colocationDirectory", Json_Decode$JsonCombinators.bool),
              cloudDirectory: field.required("cloudDirectory", Json_Decode$JsonCombinators.bool),
              internetDirectory: field.required("internetDirectory", Json_Decode$JsonCombinators.bool),
              bareMetalDirectory: field.required("bareMetalDirectory", Json_Decode$JsonCombinators.bool),
              networkDirectory: field.required("networkDirectory", Json_Decode$JsonCombinators.bool),
              colocationProjects: field.required("colocationProjects", Json_Decode$JsonCombinators.bool),
              internetProjects: field.required("internetProjects", Json_Decode$JsonCombinators.bool),
              cloudIaasProjects: field.required("cloudIaasProjects", Json_Decode$JsonCombinators.bool),
              cloudServersProjects: field.required("cloudServersProjects", Json_Decode$JsonCombinators.bool),
              networkSdwanProjects: field.required("networkSdwanProjects", Json_Decode$JsonCombinators.bool),
              networkMplsProjects: field.required("networkMplsProjects", Json_Decode$JsonCombinators.bool),
              networkPrivateLineProjects: field.required("networkPrivateLineProjects", Json_Decode$JsonCombinators.bool),
              bareMetalProjects: field.required("bareMetalProjects", Json_Decode$JsonCombinators.bool),
              resourceDownloadLeads: field.required("resourceDownloadLeads", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toJson(agreement) {
  if (agreement === "Agreement") {
    return true;
  } else {
    return false;
  }
}

function toString(value) {
  if (value === "Agreement") {
    return "Agreement";
  } else {
    return "No Agreement";
  }
}

function fromJs$1(agreement) {
  if (agreement) {
    return "Agreement";
  } else {
    return "NoAgreement";
  }
}

function fromBool(agreement) {
  if (agreement) {
    return "Agreement";
  } else {
    return "NoAgreement";
  }
}

var Agreement = {
  toJson: toJson,
  toString: toString,
  fromJs: fromJs$1,
  fromBool: fromBool
};

function toFieldName(t) {
  switch (t) {
    case "Agreement" :
        return "agreement";
    case "BareMetalDirectory" :
        return "bare_metal_directory";
    case "BareMetalProjects" :
        return "bare_metal_projects";
    case "CloudDirectory" :
        return "cloud_directory";
    case "CloudIaasProjects" :
        return "cloud_iaas_projects";
    case "CloudServersProjects" :
        return "cloud_servers_projects";
    case "ColocationDirectory" :
        return "colocation_directory";
    case "ColocationMarketplace" :
        return "colocation_marketplace";
    case "ColocationProjects" :
        return "colocation_products";
    case "InternetDirectory" :
        return "internet_directory";
    case "InternetProjects" :
        return "internet_projects";
    case "NetworkDirectory" :
        return "network_directory";
    case "NetworkMplsProjects" :
        return "network_mpls_projects";
    case "NetworkPrivateLineProjects" :
        return "network_private_line_projects";
    case "NetworkSdwanProjects" :
        return "network_sdwan_projects";
    case "ResourceDownloadLeads" :
        return "resource_download_leads";
    
  }
}

function toTitle(t) {
  switch (t) {
    case "Agreement" :
        return "Agreement";
    case "BareMetalDirectory" :
        return "Bare Metal Directory";
    case "BareMetalProjects" :
        return "Bare Metal Projects";
    case "CloudDirectory" :
        return "Cloud Directory";
    case "CloudIaasProjects" :
        return "Cloud IaaS Projects";
    case "CloudServersProjects" :
        return "Cloud Servers Projects";
    case "ColocationDirectory" :
        return "Colocation Directory";
    case "ColocationMarketplace" :
        return "Colocation Marketplace";
    case "ColocationProjects" :
        return "Colocation Projects";
    case "InternetDirectory" :
        return "Internet Directory";
    case "InternetProjects" :
        return "Internet Projects";
    case "NetworkDirectory" :
        return "Network Directory";
    case "NetworkMplsProjects" :
        return "Network MPLS Projects";
    case "NetworkPrivateLineProjects" :
        return "Network Private Line Projects";
    case "NetworkSdwanProjects" :
        return "Network SD-WAN Projects";
    case "ResourceDownloadLeads" :
        return "Resource Download Leads";
    
  }
}

var filterFields = [
  "Agreement",
  "BareMetalDirectory",
  "BareMetalProjects",
  "CloudDirectory",
  "CloudIaasProjects",
  "CloudServersProjects",
  "ColocationDirectory",
  "ColocationMarketplace",
  "ColocationProjects",
  "InternetDirectory",
  "InternetProjects",
  "NetworkDirectory",
  "NetworkMplsProjects",
  "NetworkPrivateLineProjects",
  "NetworkSdwanProjects",
  "ResourceDownloadLeads"
];

function toFilter(fields) {
  return fields.map(function (field) {
              return toFieldName(field);
            });
}

var Field = {
  toFieldName: toFieldName,
  toTitle: toTitle,
  filterFields: filterFields,
  toFilter: toFilter
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  Agreement ,
  Field ,
}
/* decoder Not a pure module */
