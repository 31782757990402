// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          publicId: js.publicId,
          url: js.url,
          width: Caml_option.nullable_to_opt(js.width),
          height: Caml_option.nullable_to_opt(js.height),
          active: js.active,
          description: js.description,
          position: js.position
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              publicId: field.required("publicId", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              width: field.required("width", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              height: field.required("height", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              position: field.required("position", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function normalize(images) {
  return {
          index: Belt_Array.map(images, (function (image) {
                  return image.id;
                })),
          dict: Belt_Array.reduce(images, ID.$$Map.make(), (function (dict, image) {
                  return Belt_Map.set(dict, image.id, image);
                }))
        };
}

function fromCloudinary(json, position) {
  return Json_Encode$JsonCombinators.object([
              [
                "publicId",
                Belt_Result.getExn(Json$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                              return field.required("public_id", Json_Decode$JsonCombinators.string);
                            })))
              ],
              [
                "width",
                Belt_Result.getExn(Json$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                              return field.required("width", Json_Decode$JsonCombinators.$$int);
                            })))
              ],
              [
                "height",
                Belt_Result.getExn(Json$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                              return field.required("height", Json_Decode$JsonCombinators.$$int);
                            })))
              ],
              [
                "url",
                Belt_Result.getExn(Json$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                              return field.required("url", Json_Decode$JsonCombinators.string);
                            })))
              ],
              [
                "position",
                position
              ]
            ]);
}

var Interim = {
  fromCloudinary: fromCloudinary
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  normalize ,
  Interim ,
}
/* decoder Not a pure module */
