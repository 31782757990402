// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as DirectoryService from "./DirectoryService.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          title: js.title
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Simple = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          id: js.id,
          title: js.title,
          services: Belt_Array.map(js.services, DirectoryService.EditProvider.fromJs)
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              services: field.required("services", Json_Decode$JsonCombinators.array(DirectoryService.EditProvider.decoder))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var EditProvider = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$2(js) {
  return {
          id: js.id,
          title: js.title,
          displayName: js.displayName,
          menuTitle: js.menuTitle,
          active: js.active,
          slug: js.slug,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              displayName: field.required("displayName", Json_Decode$JsonCombinators.string),
              menuTitle: field.required("menuTitle", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

export {
  Simple ,
  EditProvider ,
  fromJs$2 as fromJs,
  decoder$2 as decoder,
  fromJson$2 as fromJson,
}
/* decoder Not a pure module */
