// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          companyType: js.companyType,
          tickerSymbol: js.tickerSymbol,
          numberEmployees: js.numberEmployees,
          yearFounded: js.yearFounded,
          hqFullAddress: js.hqFullAddress,
          displayDialCode: js.displayDialCode,
          displayPhoneNumber: js.displayPhoneNumber,
          supportPhoneNumber: js.supportPhoneNumber,
          websiteUrl: js.websiteUrl,
          description: js.description,
          logo: js.logo,
          projectWebhookUrl: js.projectWebhookUrl,
          updatedAt: $$Date.Naive.fromString(js.updatedAt),
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              companyType: field.required("companyType", Json_Decode$JsonCombinators.string),
              tickerSymbol: field.required("tickerSymbol", Json_Decode$JsonCombinators.string),
              numberEmployees: field.required("numberEmployees", Json_Decode$JsonCombinators.string),
              yearFounded: field.required("yearFounded", Json_Decode$JsonCombinators.string),
              hqFullAddress: field.required("hqFullAddress", Json_Decode$JsonCombinators.string),
              displayDialCode: field.required("displayDialCode", Json_Decode$JsonCombinators.string),
              displayPhoneNumber: field.required("displayPhoneNumber", Json_Decode$JsonCombinators.string),
              supportPhoneNumber: field.required("supportPhoneNumber", Json_Decode$JsonCombinators.string),
              websiteUrl: field.required("websiteUrl", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              projectWebhookUrl: field.required("projectWebhookUrl", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toJson(providerDraft) {
  return Json_Encode$JsonCombinators.object([
              [
                "id",
                ID.toJson(providerDraft.id)
              ],
              [
                "name",
                providerDraft.name
              ],
              [
                "private",
                providerDraft.companyType
              ],
              [
                "ticker_symbol",
                providerDraft.tickerSymbol
              ],
              [
                "number_employees",
                providerDraft.numberEmployees
              ],
              [
                "year_founded",
                providerDraft.yearFounded
              ],
              [
                "hq_full_address",
                providerDraft.hqFullAddress
              ],
              [
                "display_dial_code",
                providerDraft.displayDialCode
              ],
              [
                "display_phone_number",
                providerDraft.displayPhoneNumber
              ],
              [
                "support_phone",
                providerDraft.supportPhoneNumber
              ],
              [
                "website_url",
                providerDraft.websiteUrl
              ],
              [
                "description",
                providerDraft.description
              ],
              [
                "logo",
                providerDraft.logo
              ],
              [
                "project_webhook_url",
                providerDraft.projectWebhookUrl
              ],
              [
                "meta_title",
                providerDraft.metaTitle
              ],
              [
                "meta_description",
                providerDraft.metaDescription
              ]
            ]);
}

export {
  fromJs ,
  decoder ,
  fromJson ,
  toJson ,
}
/* decoder Not a pure module */
