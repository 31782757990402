// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          directoryId: js.directoryId,
          dataCenterServiceId: js.dataCenterServiceId,
          active: js.active
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              directoryId: field.required("directoryId", ID.decoder),
              dataCenterServiceId: field.required("dataCenterServiceId", ID.decoder),
              active: field.required("active", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$1(js) {
  return {
          id: js.id,
          directoryProviderServiceId: js.directoryProviderServiceId,
          title: js.title,
          live: js.live,
          requested: js.requested,
          approved: js.approved
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              directoryProviderServiceId: field.required("directoryProviderServiceId", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              live: field.required("live", Json_Decode$JsonCombinators.bool),
              requested: field.required("requested", Json_Decode$JsonCombinators.bool),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var EditProvider = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$2(js) {
  return {
          id: js.id,
          title: js.title
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Filter = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2
};

function fromJs$3(js) {
  return {
          id: js.id,
          title: js.title
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Show = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$3
};

function fromJs$4(js) {
  return {
          directoryTitle: js.directoryTitle,
          directoryServices: Belt_Array.map(js.directoryServices, fromJs$3)
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              directoryTitle: field.required("directoryTitle", Json_Decode$JsonCombinators.string),
              directoryServices: field.required("directoryServices", Json_Decode$JsonCombinators.array(decoder$3))
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var ShowIndex = {
  fromJs: fromJs$4,
  decoder: decoder$4,
  fromJson: fromJson$4
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  EditProvider ,
  Filter ,
  Show ,
  ShowIndex ,
}
/* decoder Not a pure module */
