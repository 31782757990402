// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          key: js.key,
          enabled: js.enabled,
          expiresAt: $$Date.Naive.fromString(js.expiresAt),
          ownerType: js.ownerType,
          ownerId: js.ownerId,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              key: field.required("key", Json_Decode$JsonCombinators.string),
              enabled: field.required("enabled", Json_Decode$JsonCombinators.bool),
              expiresAt: field.required("expiresAt", $$Date.Naive.decoder),
              ownerType: field.required("ownerType", Json_Decode$JsonCombinators.string),
              ownerId: field.required("ownerId", ID.decoder),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
